<template>
  <ChatImagePreview />
  <div class="h-full">
    <template v-if="!channelsLoaded && !externalChat">
      <LoadTemplatesMessenger />
    </template>
    <template v-else>
      <div class="flex h-full justify-between">
        <div class="relative" :class="externalChat ? 'w-full' : ''">
          <ChannelSidebar
            :channels="channels"
            :chosen-channel="chosenChannel"
            :show-requests="showRequests"
            :external-chat="externalChat"
            @message:search="searchMessage($event)"
          />
        </div>
        <Channel
          v-if="chosenChannel && !externalChat"
          :channel="chosenChannel"
          :key="chosenChannel ? chosenChannel.cid : ''"
          class="flex-1"
        ></Channel>
        <ChatEmptyState v-else-if="!externalChat"></ChatEmptyState>
      </div>
      <UserBioModal
        @close="selectedUserBio = null"
        v-if="selectedUserBio"
        :user="selectedUserBio"
        @update-user="selectedUserBio.about = $event"
      ></UserBioModal>
      <AddMemberModal
        v-if="showAddModal"
        :channel="selectedChannel"
        @close="
          showAddModal = false;
          selectedChannel = null;
        "
      ></AddMemberModal>
      <ChatOnboarding v-if="chatOnboarding" :on-boarding="true" @close="chatOnboarding = false"></ChatOnboarding>
      <CompanyInfoModal :company="selectedCompany" v-if="selectedCompany" @close="selectedCompany = null" />
      <ChatShareReceiveDialog
        v-if="shareModal"
        :channel-data="sharedChannelData"
        @join-chat="addMembersToChannel(props.channelId)"
        @close="shareModal = false"
      />
    </template>
  </div>
</template>

<script setup>
import isEmpty from 'lodash/isEmpty';
import { useStore } from 'vuex';
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import Channel from './ChatChannel.vue';
import ChannelSidebar from './ChatSidebar.vue';
import UserBioModal from './ChatUserBioModal.vue';
import AddMemberModal from './ChatAddMemberModal.vue';
import ChatOnboarding from './ChatOnboardingModal.vue';
import ChatEmptyState from './ChatEmptyState.vue';
import CompanyInfoModal from './ChatCompanyInfoModal.vue';
import LoadTemplatesMessenger from '@/components/load_templates/LoadTemplatesMessenger.vue';
import ChatImagePreview from '@/components/chat/ChatImagePreview.vue';
import ChatShareReceiveDialog from './ChatShareReceiveDialog.vue';
import { useMapGetter } from '@/store/map-state';
import { getCompaniesByCategory } from '@/composeables/chat';
import { saveNotificationPreference, getColor, unsubscribeToMessages, listenToMessages } from '@/composeables/chat';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';

const $store = useStore();
const $emitter = useEmitter();
const $http = useHttp();
const $emit = defineEmits(['close-dialog']);
const { client, streamUser, streamUsers, channels, channelsLoaded } = useMapGetter();

const props = defineProps({
  channelId: { type: String, default: '' },
  showRequests: { type: Boolean, default: false },
  firstTimeChat: { type: Boolean, default: false },
  externalChat: { type: Boolean, default: false }
});

// COMPANY
const companies = ref([]);
const selectedCompany = ref(false);
onMounted(() => {
  $emitter.$on('show:company-info', data => {
    selectedCompany.value = data;
  });
});
watch(
  () => streamUsers.value,
  async (newVal, oldVal) => {
    if (streamUsers.value != null) {
      companies.value = await getCompaniesByCategory('All Companies', streamUsers.value);
      $store.dispatch('setStreamCompanies', companies.value);
    }
  }
);

// MESSAGES
const messages = ref([]);
const selectedUserBio = ref(null);
onMounted(() => {
  $emitter.$on('show:user-bio', user => {
    if (user.id.toString() !== client.value.user.id) {
      selectedUserBio.value = user;
    }
  });
});
onMounted(async () => {
  $emitter.$on('send-dm', async user => {
    const filter = {
      type: 'messaging',
      members: {
        $eq: [user.id, streamUser.value.id]
      },
      direct_messaging: true
    };
    const sort = [{ last_message_at: -1 }];

    let channels = await client.value.queryChannels(filter, sort, {});
    channels = channels.filter(channel => {
      let members = Object.values(channel.state.members);
      let filteredMembers = members.filter(x => x.user_id == client.value.user.id || x.user_id == user.id);
      return filteredMembers.length == 2 || (members.length == 1 && filteredMembers.length > 0);
    });
    if (channels.length <= 0) {
      let id = Math.random().toString(16).slice(2);
      let channel_id = [
        [user.name, streamUser.value.name]
          .join('_')
          .replace(/[^\w]/g, '')
          .substring(0, 64 - id.length - 1),
        id
      ].join('_');

      let selectedMembers = [client.value.user.id];
      let externalMembers = [];
      if (user.team_id != streamUser.value.team_id) {
        externalMembers.push(user.id);
      } else {
        selectedMembers.push(user.id);
      }
      const channel = await client.value.channel('messaging', channel_id, {
        team: 'messaging',
        name: user.name.split(' ')[0] + ' & ' + streamUser.value.name.split(' ')[0],
        members: selectedMembers,
        channel_state: 'private',
        channel_admins: [client.value.user.id, user.id],
        invited_users: externalMembers.length
          ? [{ invited_users: externalMembers, invited_by: { id: streamUser.value.id, name: streamUser.value.name } }]
          : [],
        direct_messaging: true
      });
      await channel.watch();
      if (externalMembers.length) {
        await channel.inviteMembers(externalMembers);
        await channel.sendMessage({
          text: `{{user:${client.value.user.id}}} has created chat.`,
          activity_status: 'created',
          activity_members: [{ id: client.value.user.id, name: client.value.user.name }]
        });
        $http.put('/mute_channel', {
          channel_id: channel.id,
          user_ids: externalMembers
        });
      }
      $emitter.$emit('channel:added', channel);
      saveNotificationPreference(selectedMembers, channel, 'all');
      setChannel(channel);
    } else {
      setChannel(channels[0]);
    }
    selectedUserBio.value = null;
  });
});

// CHANNELS
const chosenChannel = ref(null);
const selectedChannel = ref(null);
const shareModal = ref(false);

function setChannel(channel) {
  if (localStorage.getItem('selectedChats') && props.externalChat) {
    let items = JSON.parse(localStorage.getItem('selectedChats'));
    if (!items.includes(channel.cid)) {
      items.push(channel.cid);
    }
    localStorage.setItem('selectedChats', JSON.stringify(items));
  } else if (props.externalChat) {
    localStorage.setItem('selectedChats', JSON.stringify([channel.cid]));
  }
  if (props.externalChat) {
    $emitter.$emit('channel:selected', channel);
  } else if (channel) {
    chosenChannel.value = channel;
    localStorage.setItem('chosenChannel', channel.cid);
  }
}

async function getChannelData() {
  const data = $http.get(`/chat/channel_data?channel_id=${props.channelId}`);
  return data;
}

async function removeChannelDataFromUser(channel) {
  let updatedData = {};
  if (
    streamUser.value &&
    Array.isArray(streamUser.value.pinned_channels) &&
    streamUser.value.pinned_channels.includes(channel.cid)
  ) {
    let pinnedChannels = streamUser.value.pinned_channels.filter(c => c != channel.cid);
    updatedData.pinned_channels = pinnedChannels;
  }
  if (
    streamUser.value &&
    Array.isArray(streamUser.value.archive_channels) &&
    streamUser.value.archive_channels.includes(channel.cid)
  ) {
    let archivedChannels = streamUser.value.archive_channels.filter(c => c != channel.cid);
    updatedData.archive_channels = archivedChannels;
  }
  if (!isEmpty(updatedData)) {
    await client.value.partialUpdateUser({ id: streamUser.value.id, set: updatedData });
  }
}

async function addToChannel(channelId, listen) {
  let filter = { cid: { $eq: channelId } };
  let channel = await client.value.queryChannels(filter);
  let index = channels.value.findIndex(x => x.cid === channelId);
  if (index !== -1) {
    $store.dispatch('updateStreamChannel', channel[0]);
  } else {
    $store.dispatch('addStreamChannel', channel[0]);
  }
  if (listen) {
    listenToMessages(channel[0]);
  }
}

async function addMembersToChannel(channelId) {
  await $http.patch('/add_members', {
    channel_id: channelId,
    user_ids: [client.value.user.id]
  });
  setTimeout(() => {
    const channelById = channels.value.find(c => c.id === channelId);
    saveNotificationPreference([client.value.user.id], channelById, 'all');
    channelById.sendMessage({
      text: `{{user:${client.value.user.id}}} joined this chat by a link.`,
      activity_status: 'added',
      activity_members: [{ id: client.value.user.id, name: client.value.user.name }]
    });
    chosenChannel.value = channelById;
    localStorage.setItem('chosenChannel', channelById.cid);
    shareModal.value = false;
  }, 500);
}

watch(
  () => channelsLoaded.value,
  async (newVal, oldVal) => {
    if (newVal) {
      if (props.channelId) {
        const channelById = channels.value.find(c => c.id === props.channelId);
        if (channelById) {
          chosenChannel.value = channels.value.find(c => c.id === props.channelId);
          localStorage.setItem('chosenChannel', chosenChannel.value.cid);
        }
        if (window.location.search.includes('uuid') && !channelById) {
          let channelData = await getChannelData();
          if (channelData.data.success) {
            sharedChannelData.value = channelData.data.channel;
          }
          shareModal.value = true;
        }
      } else if (localStorage.getItem('chosenChannel') && channels.value.length && !props.externalChat) {
        chosenChannel.value = channels.value.find(c => c.cid == localStorage.getItem('chosenChannel'));
      }
    }
  }
);

onMounted(() => {
  if (localStorage.getItem('chosenChannel') && channels.value.length && !props.externalChat) {
    chosenChannel.value = channels.value.find(c => c.cid == localStorage.getItem('chosenChannel'));
  }
  $emitter.$on('channel:change', channel => {
    if (channel) {
      setChannel(channel);
      channel.markRead();
    } else {
      chosenChannel.value = null;
      localStorage.setItem('chosenChannel', null);
    }
  });

  $emitter.$on('channel:select', channel => {
    if (!chosenChannel.value && !props.externalChat) {
      chosenChannel.value = channel;
      channel.markRead();
      localStorage.setItem('chosenChannel', channel.cid);
    }
  });
  $emitter.$on('channel:added', channel => {
    let s_channel = channels.value.find(x => x.cid === channel.cid);
    if (!s_channel) {
      $store.dispatch('addStreamChannel', s_channel);
    }
    setChannel(channel);
  });

  client.value.on('notification.removed_from_channel', event => {
    let index = channels.value.findIndex(c => c.cid === event.channel.cid);
    $store.dispatch('removeStreamChannel', event.channel.cid);
    if (chosenChannel.value && chosenChannel.value.cid == event.cid) {
      chosenChannel.value = null;
    }
    if (props.externalChat && localStorage.getItem('acceptRequest') != event.channel.cid) {
      $emitter.$emit('channel-removed', event.channel);
    }
    removeChannelDataFromUser(event.channel);
    unsubscribeToMessages(event.channel.cid);
  });

  client.value.on('channel.updated', async event => {
    let index = channels.value.findIndex(x => x.cid === event.channel.cid);
    if (index != -1) {
      let filter = { cid: { $eq: event.channel.cid } };
      let channel = await client.value.queryChannels(filter);
      $store.dispatch('updateStreamChannel', channel[0]);
    }
  });

  client.value.on('channel.deleted', event => {
    let index = channels.value.findIndex(c => c.cid === event.channel.cid);
    $store.dispatch('removeStreamChannel', event.channel.cid);
    chosenChannel.value =
      chosenChannel.value && chosenChannel.value.cid == event.channel.cid ? null : chosenChannel.value;
    if (props.externalChat) {
      $emitter.$emit('channel-removed', event.channel);
    }
    removeChannelDataFromUser(event.channel);
  });

  $emitter.$on('channel:leave', async data => {
    let confirmationText =
      client.value.user.id == data.id
        ? 'Are you sure you want to leave this Chat?'
        : 'Are you sure you want to remove ' + data.name + '?';
    await $store.dispatch('confirm', confirmationText);
    await data.channel.removeMembers([data.id]);
    if (client.value.user.id != data.id && !data.invited) {
      await data.channel.sendMessage({
        text: `{{user:${data.id}}} was removed from the chat.`,
        activity_status: 'removed',
        activity_members: [{ id: data.id, name: data.name }]
      });
    } else if (!data.invited) {
      await data.channel.sendMessage({
        text: `{{user:${streamUser.value.id}}} has left the chat.`,
        activity_status: 'leave',
        activity_members: [{ id: streamUser.value.id, name: streamUser.value.name }]
      });
    }
    $emit('close-dialog');

    saveNotificationPreference([data.id], data.channel);
    if (client.value.user.id == data.id) {
      $store.dispatch('removeStreamChannel', data.channel.cid);
    }
    if (props.externalChat) {
      $emitter.$emit('channel-removed', data.channel);
    }
    let invitedUsers = data.channel.data.invited_users;
    let filteredAdmins = data.channel.data.channel_admins;
    if (data.invited && Array.isArray(data.channel.data.invited_users)) {
      invitedUsers = [];
      data.channel.data.invited_users.forEach(x => {
        if (x.invited_users.includes(data.id)) {
          x.invited_users = x.invited_users.filter(u => u != data.id);
          if (x.invited_users.length) {
            invitedUsers.push(x);
          }
        } else {
          invitedUsers.push(x);
        }
      });
    }
    if (Array.isArray(data.channel.data.channel_admins)) {
      filteredAdmins = data.channel.data.channel_admins.filter(x => x != data.id);
    }
    await data.channel.updatePartial({
      set: {
        channel_admins: filteredAdmins,
        invited_users: invitedUsers
      }
    });
  });

  client.value.on('notification.added_to_channel', event => {
    addToChannel(event.channel.cid, true);
  });

  client.value.on('member.added', event => {
    let listen = false;
    if (event.member.user_id == client.value.user.id && !event.member.invited) {
      listen = true;
    }
    addToChannel(event.cid, listen);
  });
});

// MODAL
const showAddModal = ref(false);
onMounted(() => {
  $emitter.$on('action:add-members', channelId => {
    let channel = channels.value.find(c => c.cid === channelId);
    if (channel) {
      selectedChannel.value = channel;
      showAddModal.value = true;
    }
  });
});

// CHAT ONBOARDING
const chatOnboarding = ref(false);
onMounted(() => {
  let userKeys = [
    'first_name',
    'last_name',
    'role_title',
    'about',
    'linkedin_url',
    'department_url',
    'telegram_url',
    'image'
  ];
  if (props.firstTimeChat && !props.externalChat && userKeys.some(x => !streamUser[x])) {
    chatOnboarding.value = true;
  }
});

// SEARCH MESSAGE
async function searchMessage(payload) {
  const channel = channels.value.find(c => c.cid === payload.channelId);
  setChannel(channel);
  if (!channel.state.messages.find(msg => msg.id === payload.id)) {
    const beforeMessages = await channel.query({
      messages: { limit: 25, id_lte: payload.id }
    });
    const afterMessages = await channel.query({
      messages: { limit: 25, id_gt: payload.id }
    });
    let messages = [...beforeMessages.messages, ...afterMessages.messages];
    $store.dispatch('updateStreamMessages', { channelCid: channel.cid, messages: messages });
  }
  setTimeout(() => {
    $emitter.$emit('message:focus', payload.id);
  }, 1000);
}
onMounted(() => {
  $emitter.$on('message:search', data => {
    searchMessage(data);
  });
});

// OTHERS
const sharedChannelData = ref(null);

watch(
  () => streamUser.value,
  (newVal, oldVal) => {
    if (!streamUser.value.background_color) {
      client.value.partialUpdateUser({ id: streamUser.value.id, set: { background_color: getColor() } });
    }
  }
);

onBeforeUnmount(() => {
  $emitter.$off('channel:change');
  $emitter.$off('channel:added');
  $emitter.$off('channel:leave');
  $emitter.$off('channel:select');
  $emitter.$off('show:user-bio');
  $emitter.$off('send-dm');
  $emitter.$off('action:add-members');
  $emitter.$off('show:company-info');
  $emitter.$off('message:search');
});
</script>
